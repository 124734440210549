import { ChangeEvent, useContext, useEffect, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components/ui
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { GroupInformationBox, InformationBox } from './components';
import { CameraAlt, Edit, GroupAdd, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
// Context
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';

// Types
import {
  ChatType,
  ConversationSatus,
  IConversation,
} from '@trii/types/dist/Conversations';
import { UserInfo, UserStatus } from '@trii/types/dist/Users';
import {
  ActionChat,
  UpdateChat,
} from 'redux/features/conversationsSlice/types/UpdateChat';
//Redux
import { useAppDispatch } from 'hooks/useAppDispatch';
import {
  selectUploadMedia,
  selectUploadMediaStatus,
} from 'redux/features/messagesSlice/messagesSlice';
import {
  selectUpdateChatFetchStatus,
  unarchiveChat,
  selectChatUnarchiveStatus,
  archiveChat,
  selectChatArchiveStatus,
  setConversationSelected,
  selectConversationSelected,
  fetchUpdateChat,
} from 'redux/features/conversationsSlice/conversationsSlice';
import { useSelector } from 'react-redux';
import { dbWorker } from 'db/db';
import { selectUser, selectUsers } from 'redux/features/userSlice/userSlice';
import useSASAvatarURL from 'hooks/useSASAvatarURL';
import { Media } from 'redux/features/messagesSlice/types/Media';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 1,
  padding: 2,
  width: '100%',
  height: '100%',
  overflowY: 'auto',
});

const InnerContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 1,
  width: '100%',
});

const AvatarBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    minWidth: 15,
    height: 15,
    zIndex: 0,
  },
  zIndex: 0,
}));

const AvatarStyled = styled(Avatar)({
  width: 90,
  height: 90,
  cursor: 'pointer',
});

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
  width: '100%',
});

const MembersBox = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingX: '1rem',
});

const GroupInfoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
});

const ActionBox = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  position: 'relative',
  paddingX: '1rem',
  '& button': {
    alignSelf: 'end',
    width: '25%',
    marginTop: '1rem',
  },
});

const InternalUserInfo = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    internalGroupInfo,
    setInternalGroupInfo,
    setConversationId,
    setContactInfo,
    setModalTitle,
    setOpenModalList,
    getUsers,
    handleSelectConversation,
    // handleUploadFile,
    handleSingleFileUpload,
  } = useContext(conversationsContext);

  const [userInfo, setUserInfo] = useState<UserInfo>(null);
  const [title, setTitle] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [editTitle, setEditTitle] = useState<boolean>(false);
  const [userSelectedInfo, setUserSelectedInfo] = useState<string>('');

  const conversationSelected = useSelector(selectConversationSelected);
  const user = useSelector(selectUser);
  const uploadMedia = useSelector(selectUploadMedia);
  const uploadMediaStatus = useSelector(selectUploadMediaStatus);
  const updateChatFetchStatus = useSelector(selectUpdateChatFetchStatus);
  const unarchiveStatus = useSelector(selectChatUnarchiveStatus);
  const archiveStatus = useSelector(selectChatArchiveStatus);
  const users = useSelector(selectUsers);

  const isLoading = uploadMediaStatus === 'loading';
  const isUpdating = updateChatFetchStatus === 'loading';
  const isUnarchiving = unarchiveStatus === 'loading';
  const isArchiving = archiveStatus === 'loading';
  const chatUserInfo = conversationSelected?.chatMembers[0];
  const status = users.find((user) => user.id === chatUserInfo?.userId)?.status;
  const adminUser = conversationSelected?.chatMembers.find(
    (member) => member.isAdmin
  );
  const isAdmin = adminUser?.userId === user.uid;

  const getUserStatusBadgeColor = () => {
    switch (status) {
      case UserStatus.ONLINE:
        return 'success';
      case UserStatus.AWAY:
        return 'warning';
      case UserStatus.BUSY:
        return 'error';

      default:
        return 'default';
    }
  };

  async function handleUnarchiveChat() {
    try {
      const updatedChat = await dispatch(unarchiveChat(conversationSelected.id));

      handleSelectConversation(updatedChat.payload as IConversation);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleArchiveChat() {
    try {
      const updatedChat = await dispatch(archiveChat(conversationSelected.id));

      handleSelectConversation(updatedChat.payload as IConversation);
    } catch (err) {
      console.log(err);
    }
  }

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleEditTitle = () => {
    setEditTitle(true);
  };

  const handleSaveTitle = () => {
    // Update chat name
    const data = {
      chatId: conversationSelected?.id,
      action: 'updateName',
      value: title,
    };

    dbWorker.postMessage({
      action: 'setChat',
      data: { ...conversationSelected, chatName: title },
    });

    dispatch(fetchUpdateChat(data as UpdateChat));
    setEditTitle(false);
  };

  const changeAvatar = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = Array.from(event.target.files)[0];
      const media = await handleSingleFileUpload(file);

      const { url } = media as unknown as Media;

      setImage(url);

      const data = {
        chatId: conversationSelected?.id,
        action: 'updateImage',
        value: url,
      };

      dbWorker.postMessage({
        action: 'setChat',
        data: { ...conversationSelected, chatImage: url },
      });
      dispatch(fetchUpdateChat(data as UpdateChat));
    } catch (err) {
      console.error('Error changing group image: ', err);
    }
  };

  const handleChangeAvatar = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    // Handle upload file
    inputElement.addEventListener('change', changeAvatar.bind(this));
    inputElement.click();
  };

  const handleSelectUser = (id: string) => {
    if (id === userSelectedInfo) {
      setUserSelectedInfo('');
    } else {
      setUserSelectedInfo(id);
    }
  };

  const handleClick = (state: ActionChat) => {
    const data = {
      chatId: conversationSelected?.id,
      action: state,
    };

    dbWorker.postMessage({
      action: 'deleteChat',
      data: conversationSelected?.id,
    });

    dispatch(fetchUpdateChat(data as UpdateChat));
    dispatch(setConversationSelected(null));

    setConversationId('');
    setContactInfo(null);
  };

  const handleAddParticipant = () => {
    setModalTitle(t('conversations.internalChat.addParticipant'));
    setOpenModalList(true);
    getUsers();
  };

  useEffect(() => {
    setInternalGroupInfo(conversationSelected?.chatMembers);
    setTitle(conversationSelected?.chatName);
    setImage(conversationSelected?.chatImage);
  }, [conversationSelected]);

  const imageURLWithAccess = useSASAvatarURL(image);

  return (
    <Container>
      <InnerContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1,
            width: '100%',
            marginBottom: '1rem',
          }}
        >
          <AvatarBadge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            color={
              conversationSelected.type === ChatType.DIRECT
                ? getUserStatusBadgeColor()
                : undefined
            }
            badgeContent={
              conversationSelected?.type === ChatType.GROUP &&
              (isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton onClick={handleChangeAvatar}>
                  <CameraAlt
                    sx={{
                      color: (theme) => theme.palette.primary.light,
                    }}
                  />
                </IconButton>
              ))
            }
            sx={{
              '& .MuiBadge-badge': conversationSelected.type === ChatType.DIRECT && {
                backgroundColor: status === UserStatus.OFFLINE ? 'grey' : undefined,
              },
            }}
          >
            <AvatarStyled
              src={imageURLWithAccess}
              alt={
                conversationSelected?.type === ChatType.DIRECT
                  ? userInfo?.name
                  : conversationSelected?.chatName
              }
            >
              {conversationSelected?.type === ChatType.DIRECT
                ? userInfo?.name.charAt(0).toUpperCase()
                : conversationSelected?.chatName.charAt(0).toUpperCase()}
            </AvatarStyled>
          </AvatarBadge>
          <TitleBox>
            <TextField
              value={title}
              onChange={handleChangeTitle}
              disabled={!editTitle}
              size="small"
              variant={!editTitle ? 'standard' : 'filled'}
              hiddenLabel
            />
            {conversationSelected?.type === ChatType.GROUP &&
              (isUpdating ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {editTitle ? (
                    <IconButton onClick={handleSaveTitle}>
                      <Save
                        sx={{
                          color: (theme) => theme.palette.primary.light,
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton onClick={handleEditTitle}>
                      <Edit
                        sx={{
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      />
                    </IconButton>
                  )}
                </>
              ))}
          </TitleBox>
        </Box>
        <MembersBox>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            {t('global.members')}
          </Typography>
          {isAdmin && (
            <IconButton onClick={handleAddParticipant}>
              <GroupAdd sx={{ color: 'text.disabled' }} />
            </IconButton>
          )}
        </MembersBox>
        <Divider variant="middle" sx={{ width: '100%' }} />
        <GroupInfoBox>
          {conversationSelected?.type === ChatType.GROUP &&
            internalGroupInfo &&
            internalGroupInfo.length > 0 &&
            internalGroupInfo.map((member, i) => (
              <GroupInformationBox
                key={i}
                member={member}
                handleSelectUser={handleSelectUser}
                userSelectedInfo={userSelectedInfo}
              />
            ))}
          {conversationSelected?.type === ChatType.DIRECT && (
            <>
              <InformationBox
                value={conversationSelected?.chatMembers[0]?.user?.email}
                icon={<EmailIcon />}
              />
              <InformationBox
                value={conversationSelected?.chatMembers[0]?.user?.phone}
                icon={<LocalPhoneIcon />}
              />
            </>
          )}
        </GroupInfoBox>
      </InnerContainer>
      <ActionBox>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          {t('conversations.internalChat.archiveChat')}
        </Typography>
        <Divider
          variant="middle"
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: '40px',
            left: 0,
            marginX: 0,
          }}
        />
        {conversationSelected.status === ConversationSatus.ARCHIVED ? (
          <LoadingButton
            disabled={isUnarchiving}
            loading={isUnarchiving}
            variant="contained"
            size="small"
            onClick={handleUnarchiveChat}
          >
            {t('conversations.internalChat.unarchiveBtn')}
          </LoadingButton>
        ) : (
          <LoadingButton
            disabled={isArchiving}
            loading={isArchiving}
            variant="contained"
            size="small"
            onClick={handleArchiveChat}
          >
            {t('conversations.internalChat.archiveBtn')}
          </LoadingButton>
        )}
      </ActionBox>
      <ActionBox>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.disabled,
          }}
        >
          {t('conversations.internalChat.close')}
        </Typography>
        <Divider
          variant="middle"
          sx={{
            width: '100%',
            position: 'absolute',
            bottom: '40px',
            left: 0,
            marginX: 0,
          }}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => handleClick('closeChat')}
        >
          {t('conversations.internalChat.closeBtn')}
        </Button>
      </ActionBox>
      {conversationSelected?.type === ChatType.GROUP && (
        <ActionBox>
          <Typography
            sx={{
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            {t('conversations.internalChat.exitGroup')}
          </Typography>
          <Divider
            variant="middle"
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '40px',
              left: 0,
              marginX: 0,
            }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClick('leaveGroup')}
          >
            {t('conversations.internalChat.exitBtn')}
          </Button>
        </ActionBox>
      )}
    </Container>
  );
};

export default InternalUserInfo;
