import { useState, useContext, useEffect, SetStateAction } from 'react';
import { darken } from 'polished';
// Translation
import { useTranslation } from 'react-i18next';
// Context
import { messagesContext } from 'features/Views/Conversations/context/MessagesProvider/MessagesProvider';
import { conversationsContext } from 'features/Views/Conversations/context/ConversationsProvider/ConversationsProvider';
// Redux
import { useSelector } from 'react-redux';
// Components/ui
import { Box } from '@mui/material';
// Components
import {
  AudioMessage,
  CommentMessage,
  Document,
  Email,
  Image,
  Map,
  MessageActions,
  MessageReactionsSelector,
  MessageReactionsTool,
  MessageStatus,
  Text,
  Reactions,
} from './components';
// Slice
import { getUser, selectUser } from 'redux/features/userSlice/userSlice';
// Types
import {
  CommentData,
  IMessage,
  MessageDirection,
  MessageImage,
  MessageVideo,
} from '@trii/types/dist/Common/Messages';
import { ChatType } from '@trii/types/dist/Conversations';
import MediaFile from 'features/Views/Conversations/context/MessagesProvider/types/MediaFile';
// Moment
import moment from 'moment';
// Hooks
import { useUserInfo } from 'hooks/useUserInfo';
// Components
import { ReplyMessage } from 'components';
// DB
import db from 'db/db';
import { Star } from '@mui/icons-material';
import { selectConversationSelected } from 'redux/features/conversationsSlice/conversationsSlice';
import { UserTrii } from '@trii/types/dist/Users';
import { useAppDispatch } from 'hooks/useAppDispatch';

const REGEX_BASE64 = /^data:([a-z]+\/[a-z0-9-+.]+);base64/;
const FORMAT_DATE = 'DD/MM/YYYY HH:mm';

type TextProps = {
  message: IMessage;
  setFilesDoneLoading: (value: SetStateAction<boolean>) => void;
};

const TextType = ({ message, setFilesDoneLoading }: TextProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);
  const conversationSelected = useSelector(selectConversationSelected);

  const { getImage } = useContext(conversationsContext);
  const { sendMessage, handleUpload, highligthedMessageId } =
    useContext(messagesContext);

  const { getUserInfo } = useUserInfo();

  const [icon, setIcon] = useState<React.ReactNode>(null);
  const [over, setOver] = useState<boolean>(false);
  const [files, setFiles] = useState<MediaFile[]>([]);
  const [audioUrl, setAudioUrl] = useState<string>(null);
  const [showReply, setShowReply] = useState<boolean>(true);
  const [userInformation, setUserInformation] = useState<string>('');
  const [messageReferenceInfo, setMessageReferenceInfo] = useState<IMessage>(null);
  const [newComment, setNewComment] = useState<CommentData>(null);
  const [commentName, setCommentName] = useState<string>('');
  const {
    direction,
    documents,
    timestamp,
    text,
    buttons,
    audio,
    images,
    videos,
    email,
    userId,
    id,
    isLoaded,
    messageReference,
    comment,
    locations,
    stickers,
    reactions,
    header,
    footer,
  } = message;
  const dateHour = moment(timestamp).format('HH:mm');
  const handleOver = () => {
    setOver(true);
  };

  const handleOut = () => {
    setOver(false);
  };
  if (message.id === highligthedMessageId) console.log('HIGHLIGTHING THE MESSAGE');
  const convertDataUrlToBlob = (dataUrl: string) => {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  const filterFiles = (files: MediaFile[]) => {
    return files.filter((image) => {
      if (image) {
        const splitBase64 = image.url.split(',')[0];
        const isBase64 = REGEX_BASE64.test(splitBase64);
        if (isBase64) {
          const file = new File([convertDataUrlToBlob(image.url)], image.filename);
          return {
            ...image,
            file,
          };
        }
      }
    });
  };

  const verifyAllLoaded = (files: MediaFile[]) => {
    if (files && files.length > 0) {
      return files.every((file) => {
        if (file) {
          const splitBase64 = file.url.split(',')[0];
          const isBase64 = REGEX_BASE64.test(splitBase64);
          return !isBase64;
        }
      });
    }
  };

  const handleTryAgain = async () => {
    const filterUploadImages = files.length > 0 && filterFiles(files);
    const filterUploadVideos = videos.length > 0 && filterFiles(videos);
    const newImages =
      filterUploadImages.length > 0 &&
      ((await handleUpload(filterUploadImages, id)) as MediaFile[]);
    const newVideos =
      filterUploadVideos.length > 0 &&
      ((await handleUpload(filterUploadVideos, id)) as MediaFile[]);
    const isAllImagesCharged =
      newImages && newImages.length > 0 && verifyAllLoaded(newImages);
    const isAllVideosCharged =
      newVideos && newVideos.length > 0 && verifyAllLoaded(newVideos);
    if (newImages && newVideos && isAllImagesCharged && isAllVideosCharged) {
      sendMessage({
        ...message,
        images: newImages,
        videos: newVideos,
        isLoaded: true,
      });
    } else if (newImages && isAllImagesCharged) {
      sendMessage({
        ...message,
        images: newImages,
        isLoaded: true,
      });
    } else if (newVideos && isAllVideosCharged) {
      sendMessage({
        ...message,
        videos: newVideos,
        isLoaded: true,
      });
    }
  };

  const handleTryAgainAudio = async () => {
    if (REGEX_BASE64.test(message?.audio?.url)) {
      sendMessage(message);
    }
  };

  const getUserInformation = async (id: string) => {
    if (user) {
      if (user.uid === id) {
        return t('conversations.message.your');
      }
    }
    const newUser = await getUserInfo(id);
    return newUser?.name;
  };

  const setUser = async () => {
    const name = await getUserInformation(userId);
    if (conversationSelected && conversationSelected?.type === ChatType.DIRECT) {
      setUserInformation('');
    } else {
      setUserInformation(name);
    }
  };

  const setCommentUser = async () => {
    const name = await getUserInformation(comment.userId);
    setCommentName(name);
  };

  const getMessage = async () => {
    const newMessage = await db.messages.get(messageReference.messageId);

    setMessageReferenceInfo(newMessage);
  };

  async function getSas() {
    const response = await dispatch(getUser());
    const userTrii = response.payload as UserTrii;
    const { storageAzureSAS } = userTrii;

    return storageAzureSAS.sas;
  }

  useEffect(() => {
    if (userId) {
      setUser();
    }
  }, [userId]);

  useEffect(() => {
    if (comment) {
      setCommentUser();
    }
  }, [comment]);

  useEffect(() => {
    // This useEffect hook is used to fetch the user's Azure Storage Access Signature (SAS) and update the URLs of images, videos, and files with the SAS.
    function addSasToMediaUrls(
      mediaArray: (MessageImage | MessageVideo | MediaFile)[] | null,
      sas: string
    ) {
      if (!mediaArray) {
        return [];
      }

      return mediaArray.map((mediaItem) => {
        return {
          ...mediaItem,
          url: mediaItem.url + sas,
        };
      });
    }

    const setMedia = async () => {
      const expiredAtDate = new Date(user.storageAzureSAS.expireAt);
      let sas = '';

      if (expiredAtDate < new Date()) {
        sas = await getSas();
      } else {
        sas = user.storageAzureSAS.sas;
      }
      const imagesWithAccess = addSasToMediaUrls(images, sas);
      const videosWithAccess = addSasToMediaUrls(videos, sas);
      const filesWithAccess = addSasToMediaUrls(files, sas);

      if (imagesWithAccess?.length > 0 && videosWithAccess?.length > 0) {
        setFiles([...imagesWithAccess, ...videosWithAccess]);
      } else if (imagesWithAccess?.length > 0) {
        setFiles([...filesWithAccess, ...imagesWithAccess]);
      } else if (videosWithAccess?.length > 0) {
        setFiles([...filesWithAccess, ...videosWithAccess]);
      }
    };

    setMedia();
    setFilesDoneLoading(true);
  }, []);

  useEffect(() => {
    async function setAudioUrlWithAccess() {
      const expiredAtDate = new Date(user.storageAzureSAS.expireAt);
      let sas = '';

      if (expiredAtDate < new Date()) {
        sas = await getSas();
      } else {
        sas = user.storageAzureSAS.sas;
      }

      setAudioUrl(audio.url + sas);
    }

    if (audio && audio.url && !REGEX_BASE64.test(audio.url)) {
      setAudioUrlWithAccess();
    }
  }, [audio]);

  useEffect(() => {
    if (files && files.length > 1) {
      setShowReply(false);
    } else {
      setShowReply(true);
    }
  }, [files]);

  useEffect(() => {
    if (messageReference) {
      getMessage();
    }
  }, [messageReference]);

  useEffect(() => {
    if (conversationSelected && conversationSelected?.type === ChatType.EXTERNAL) {
      const { icon: newIcon } = getImage(conversationSelected?.channelInfo.type);
      setIcon(newIcon);
    }
  }, [conversationSelected]);

  useEffect(() => {
    if (comment) {
      setNewComment(comment);
    }
  }, [comment]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent:
          direction === MessageDirection.OUT ? "flex-end" : "flex-start",
        width: "100%",
      }}
      onMouseOver={handleOver}
      onMouseOut={handleOut}
    >
      <Box
        sx={(theme) => ({
          backgroundColor:
            highligthedMessageId === id
              ? theme.palette.mode === "light"
                ? darken(0.1, "darkgrey")
                : darken(0.1, "lightgrey")
              : direction === MessageDirection.IN
              ? theme.palette.mode === "light"
                ? theme.palette.background.default
                : //@ts-ignore
                  theme.palette.background.dropdownMenu
              : //@ts-ignore
                theme.palette.messageBox,
          transition: "background-color 0.15s ease",
          backgroundImage:
            direction === MessageDirection.IN &&
            "linear-gradient(to bottom, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0))",
          float: direction === MessageDirection.IN ? "left" : "right",
          marginLeft: direction === MessageDirection.IN ? "5px" : "20px",
          marginRight: direction === MessageDirection.IN ? "20px" : "5px",
          borderRadius: `5px 5px ${
            direction === MessageDirection.IN ? "5px 0px" : "0px 5px"
          }`,

          maxWidth: "80%",
          height: "100%",
          padding: "0rem .5rem",
          paddingTop: ".3rem",
          minWidth: "11rem",
          position: "relative",
        })}
      >
        {/* USER INFO */}
        {conversationSelected &&
          conversationSelected?.type !== ChatType.DIRECT && (
            <Box
              sx={{
                display: "flex",
                fontSize: "11px",
                color: (theme) => theme.palette.text.primary,
                justifyContent: "space-between",
              }}
            >
              <Box display="flex" alignItems="center" mb={0.5}>
                <Box mr={0.5}>{icon}</Box>
                <Box sx={{ opacity: 0.5 }}>{userInformation}</Box>
              </Box>

              {/* <MessageReactionsSelector
            shardKey={shardKey}
            spaceId={spaceId}
            msgId={id}
          />
          <MessageReactionsTool messageId={id} over={over} direction={direction} /> */}
              {reactions && reactions.length > 0 && (
                <Reactions data={reactions} direction={direction} />
              )}
            </Box>
          )}

          {files && files?.length <= 0  && (
        <Box>
            <MessageActions
              over={over}
              setOver={setOver}
              message={message}
              showReply={showReply}
              direction={direction}
            />
        </Box>
          )}
        {/* END USER INFO */}
        {messageReference && (
          <ReplyMessage messageReply={messageReferenceInfo} />
        )}
        {/* MESSAGE BODY */}
        {text && (
          <Text
            header={header}
            text={text?.body}
            buttons={buttons}
            footer={footer}
          />
        )}
        {audio && (
          <AudioMessage
            audioUrl={audioUrl}
            messageId={id}
            handleTryAgainAudio={handleTryAgainAudio}
          />
        )}
        {files && files?.length > 0 && (
          <Image
            handleTryAgain={handleTryAgain}
            files={files}
            messageId={id}
            type="file"
            isLoaded={isLoaded}
          />
        )}
        {email && (
          <Email email={email} date={moment(timestamp).format(FORMAT_DATE)} />
        )}
        {documents && documents.length > 0 && (
          <Document documents={documents} messageId={id} />
        )}
        {/* maps */}
        {locations && locations.length > 0 && (
          <Map locations={locations} messageId={id} />
        )}
        {/* Stickers */}
        {stickers && stickers.length > 0 && (
          <Image
            handleTryAgain={handleTryAgain}
            files={stickers}
            messageId={id}
            isLoaded={isLoaded}
            type="sticker"
          />
        )}
        {/* END MESSAGE BODY */}
        {comment && (
          <CommentMessage
            message={message}
            commentName={commentName}
            comment={newComment}
          />
        )}
        {/* CHANNEL INFO */}
        <Box
          sx={{
            display: "flex",
            fontSize: "11px",
            color: (theme) => theme.palette.text.primary,
            justifyContent: "flex-end",
            mt: 1,
          }}
        >
          <Box display="flex" alignItems="center" gap={1} paddingTop={"-5px"}>
            <Box>{dateHour}</Box>
            {direction === MessageDirection.OUT && (
              <MessageStatus message={message} />
            )}
            {message.isHighlighted && (
              <Star
                sx={{
                  color: (theme) => theme.palette.primary.dark,
                  fontSize: 15,
                }}
              />
            )}
          </Box>
        </Box>
        {/* END CHANNEL INFO */}
      </Box>
    </Box>
  );
};

export default TextType;
